<template>
  <div id="trip">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />行程详情
        </van-col>
        <van-col span="14" style="text-align: right" @click="onEditClick">
          <!-- <input
            type="text"
            class="search-input"
            v-model="query.plan_name"
            @blur="onRefresh"
          /> -->
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col>
        <!-- <van-col
          span="7"
          style="text-align: right; z-index: 1001"
          @click="posterShow = false"
          v-show="posterShow"
        >
          <svg-icon
            :data_iconName="'close'"
            :className="'back_icon'"
            style="z-index: 1001"
          />
        </van-col>
        <van-col
          span="7"
          style="text-align: right; z-index: 1001"
          @click="posterShow = false"
          v-show="posterShow"
        >
          <svg-icon
            :data_iconName="'baocun'"
            :className="'back_icon'"
            v-show="posterShow"
            style="z-index: 1001"
          />
        </van-col> -->
      </van-row>
    </div>
    <div class="trip-body">
      <div class="trip-body-item">
        <van-row class="trip-item-body">
          <van-col span="6">
            <van-image class="item-avatar" round :src="plans.image_url" />
          </van-col>
          <van-col span="15">
            <div class="item-name">{{ plans.plan_name }}</div>
            <!-- <div class="item-sub-text">
              {{ plans.travel_company ? plans.travel_company : "暂无" }}
            </div> -->
            <!-- <div class="item-sub-text">
              {{ plans.description ? plans.description : "暂无" }}
            </div> -->
          </van-col>
          <van-col span="3" @click="onDeleteClick">
            <svg-icon :data_iconName="'delete'" :className="'edit_icon'" />
          </van-col>
        </van-row>
        <van-row class="trip-info">
          <van-col span="8">
            <div class="trip-start-address">
              {{ plans.start_addr ? plans.start_addr : "暂无" }}
            </div>
          </van-col>
          <van-col span="8">
            <div class="to">
              <svg-icon :data_iconName="'to'" :className="'to-icon'" />
            </div>

            <!-- <div class="trip-items" @click.stop="onDetailClick(item)">
                  行程景点
                </div> -->
          </van-col>
          <van-col span="8">
            <div class="trip-end-address">
              {{ plans.end_addr ? plans.end_addr : "暂无" }}
            </div>
          </van-col>
        </van-row>
        <!-- <div class="circle-left"></div>
        <div class="circle-right"></div> -->
        <div class="trip-step" style="padding-bottom: 4rem">
          <van-steps
            direction="vertical"
            active-color="#969799"
            active-icon="clock"
            :active="-1"
          >
            <van-step v-for="(item, index) in plans.nodes" :key="index">
              <h3>{{ item.node_name }}</h3>
              <p>{{ item.node_description }}</p>
            </van-step>
          </van-steps>
          <div class="btn" @click="Doposter">生成海报</div>
        </div>
      </div>
    </div>

    <!-- <Poster :painting="painting" v-if="posterShow"></Poster> -->
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
import Poster from "../poster/index.vue";
import { Toast, Dialog } from "vant";

export default {
  name: "trip",
  components: {
    Tarbar,
    Poster,
  },
  data() {
    return {
      plans: {},
      active: 1,
      show: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      posterShow: false,
     
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该行程吗?",
      })
        .then(() => {
          // on confirm
          _self.delete_travel_plan();
        })
        .catch(() => {
          // on cancel
        });
    },
    async delete_travel_plan() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_travel_plan(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
    onEditClick() {
      this.$router.push({
        path: "/trip/edit",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    onShowClick(item) {
      this.item = item;
      this.show = true;
    },
    async get_travel_plan(id) {
      const res = await this.$apis.get_travel_plan(id);
      this.plans = res.data;
    },
    Doposter(){
      // this.$router.push('/poster')
      this.$router.push({path:"/poster",query:{id:this.$route.query.id}});
    }
  },
  created() {
    const id = this.$route.query.id;
    // this.onRefresh();
    this.get_travel_plan(id);
  },
};
</script>

<style scoped>
#trip {
  height: 100vh;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-body {
  height: 85vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
}
.trip-body-item {
  background: #ffff;
  /* border-radius: 20px; */
  width: 100%;
  height: 3.2rem;
  position: relative;
  /* padding: 0.2rem; */
  margin-bottom: 10px;
  z-index: 10;
  /* height: 85vh; */
}
.trip-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.trip-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.trip-body-item .item-name {
  margin: .3rem 0 0 0;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  /* height: 1.6rem;
  line-height: 1.2rem; */
}
.trip-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
  /* position: absolute;
  top: 0.8rem;
  left: 1.3rem; */
}
.trip-body-item .item-agency-text {
  font-size: 14px;
  color: #666;
  position: absolute;
  top: 1.05rem;
  left: 1.3rem;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.border-dashed-bottom {
  border-bottom: 1px dashed #f3f3f3;
}
.trip-info {
  /* margin-top: 1.6rem; */
  height: 1.6rem;
}
.trip-start-time,
.trip-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.trip-start-address,
.trip-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
  border-top: 1px solid #cccccc;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 50%;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 50%;
  right: -0.2rem;
  border-radius: 50%;
}
.to-icon {
  display: inline-block;
  width: 100%;
}
.to {
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-item-body {
  height: 1.6rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.delete_btn {
  text-align: center;
  height: 0.5rem;
  margin-top: 10px;
  background: crimson;
  color: #fff;
  border-radius: 0.25rem;
  font-size: 0.3rem;
}
.edit-icon {
  font-size: 0.6rem;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
